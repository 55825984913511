import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, firstValueFrom, tap } from 'rxjs';
import { ConfigService } from './config.service';
import { Credentials } from '../../shared/models/credentials';
import { User } from '../../shared/models/user.model';
import { UserService } from './user.service';
import * as Sentry from "@sentry/angular-ivy";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private urlLogin: string = 'authorize/login';
  public redirectUrl: string;
  private token: string = '';
  public CurrentUser?: User;

  private signedUserSubject = new BehaviorSubject<User | null>(null);

  constructor(private http: HttpClient, private config: ConfigService, private userService: UserService) {
    
    this.token = localStorage.getItem('access_token') ?? '';
    const struser = localStorage.getItem('user');
    let user = struser ? JSON.parse(struser) : null;
    this.CurrentUser = user ? new User(user.Id, user.UserName, user.LastName, user.FirstName,
      user.MiddleName, user.Email, user.RoleName, `${user.FirstName} ${user.LastName}`) : undefined;
    this.setCurrentUser(this.CurrentUser);
  }
  

  login(username: string, password: string): Observable<any> {
    
    return this.http.post(this.config.getAPIUrl() + this.urlLogin, new Credentials(username, password)).      
      pipe(
        tap((data: any) => {
          this.token = data.auth_token;
        if (this.token != '') {
          localStorage.setItem('access_token', this.token);
          localStorage.setItem('expires_in', data.expires_in);
          let user = JSON.stringify(data.user);
          localStorage.setItem('user', user);
          this.CurrentUser = new User(data.user.Id, data.user.UserName, data.user.LastName,
            data.user.FirstName, data.user.MiddleName, data.user.Email, data.user.RoleName, `${data.user.FirstName} ${data.user.LastName}`);
          this.setCurrentUser(this.CurrentUser);
          return true;
          }
          return false;
      })
    );
  }

  getTest() {
    return this.http.get(this.config.getAPIUrl() + 'authorize/getTest', { responseType: 'text' }).
      pipe(
        tap((data: any) => {

          return data;
        })
      );
  }

  logout(): void {
    this.token = '';
    localStorage.removeItem('access_token');
    localStorage.removeItem('expires_in');
    Sentry.setUser(null);
    this.CurrentUser = undefined;
  }

  isAuthenticated(): boolean {
    return this.token != null && this.token != '';
  }

  getToken() {
    return localStorage.getItem('access_token');
  }

  private getTokenItem() {
    
  }

  public isUserAdmin() {
    return this.CurrentUser?.roleName == 'Адміністратор';    
  }

  public setCurrentUser(newUser: User | undefined) {
    this.CurrentUser = newUser;
    if (newUser) {
      Sentry.setUser({ email: newUser.userName });
    } else {
      Sentry.setUser(null);
    }
    this.signedUserSubject.next(this.CurrentUser ?? null);
  }

  public getCurrentUser(): Observable<User | null>{
    return this.signedUserSubject.asObservable();
  }
}
