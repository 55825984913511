import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private APIUrl = environment.apiUrl; // 'https://localhost:7105/api/';
  private azureStorageSASUrl = environment.azureStorageSASUrl; // 'https://xgisstorage.blob.core.windows.net/?';
  private azureStorageCatalog = environment.azureStorageCatalog;
  
  constructor() { }

  public getAPIUrl() {
    return this.APIUrl;
  }

  get AzureStorageSASUrl() {
    return this.azureStorageSASUrl;
  }

  get AzureStorageCatalog() {
    return this.azureStorageCatalog;
  }
}
