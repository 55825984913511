import { CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';
import { inject } from "@angular/core";
import { AuthService } from './auth.service';

export const AdminGuard: CanActivateFn = (route, state) => {
  const authService = inject(AuthService);
  if (!authService.isAuthenticated()) {
    authService.redirectUrl = state.url;
    inject(Router).navigate(['/login']);
    return false;
  }
  return true;
};
