import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';
import { Observable, catchError, map, throwError } from 'rxjs';
import { User } from '../../shared/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  private urlUser: string = `${this.config.getAPIUrl()}account/users/`;
  private urlCurrentUser: string = `${this.config.getAPIUrl()}account/user/`;
  
  constructor(private http: HttpClient, private config: ConfigService) { }

  getAllUsers(): Observable<User[]> {
    return this.http.get(this.urlUser)
      .pipe(
        map(data => {
          return <User[]>data;
        }),
        catchError(error => {
          return throwError(() => error);
        })
      );
  }

  getUser(id: string): Observable<User> {

    return this.http.get(`${this.urlUser}${id}`)
      .pipe(
        map(data => {
          return <User>data;
        }),
        catchError(error => {
          return throwError(() => error);
        })
      );
  }


  deleteUser(id: string): Observable<any> {
    return this.http.delete<any>(`${this.urlUser}${id}`)
      .pipe(
        catchError(error => {
          return throwError(() => error);
        })
      );
  }

  addUser(user: User): Observable<any> {
    return this.http.post(this.urlUser, user)
      .pipe(
        catchError(error => {
          return throwError(() => error);
        })
      );
  }

  updateUser(id: string, userData: User): Observable<any> {
    const url = `${this.urlUser}${id}`;
    return this.http.put(url, userData)
      .pipe(
        catchError(error => {
          return throwError(() => error);
        })
      );
  }

  getCurrentUser(): Observable<User> {

    return this.http.get(this.urlCurrentUser)
      .pipe(
        map(data => {
          return <User>data;
        }),
        catchError(error => {
          return throwError(() => error);
        })
      );
  }
}
