export class User {
  // Note: Using only optional constructor properties without backing store disables typescript's type checking for the type
  constructor(
    id?: string,
    userName?: string,
    lastName?: string,
    firstName?: string,
    middleName?: string,
    email?: string,
    roleName?: string,
    fullName?: string) {

    this.id = id;
    this.userName = userName;    
    this.lastName = lastName;
    this.firstName = firstName;
    this.middleName = middleName;
    this.email = email;
    this.roleName = roleName;
    this.fullName = fullName;
  }

  get friendlyName() {

    let name = this.fullName || this.userName ;

    return name;
  }

  public id?: string;
  public userName?: string;
  public fullName?: string;
  public lastName?: string;
  public firstName?: string;
  public middleName?: string;
  public email?: string;
  public isEnabled: boolean;
  public phoneNumber?: string;
  public roleName?: string;
  public currentPassword: string;
  public newPassword: string;
}
