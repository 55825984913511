import { ErrorHandler, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService extends ErrorHandler {

  constructor() {
    super();
  }

  override handleError(error: Error) {
     // TODO Manually log exception
  }
}
